.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;

    /*to stay on top*/
    position: fixed;
    width: 100%;
    height: 7vh;
    z-index: 10;

    background: rgba((255), 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.dashedline{
     position: fixed;
     width: 100%;
     z-index: 10;
    margin-top: 3.5rem;
}

.header-bg{ 
    background-color: rgba(0, 0, 0, 0.85);
    transition: 0.5s;
}

.logo{
    color: #fff;
    font-size: 1.3rem;
    font-weight: 200;
    padding-left: 4rem;
}

li{
    font-size: 1.3rem;
    padding-right: 2rem;
}

.blinkingcursor{
    /* font-size: 1.3rem; */
    color: #fff;
    animation: blink 1s step-end infinite;
}

@keyframes blink{
    from, to{
        visibility: visible;
    }
    50%{
        visibility: hidden;
    }
}

@media screen and (max-width:640px){
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
    
        /*to stay on top*/
        position: fixed;
        width: 100%;
        height: 4rem;
    
        background: rgba((255), 255, 255, 0.25);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
    }

    .logo{
        font-size: 1rem;
        padding-left: 2rem;
    }

    li{
        font-size: 1rem;
        padding-right: 1rem;
    }
    .dashedline{
        position: fixed;
        width: 100%;
        z-index: 10;
       margin-top: 3.7rem;
   }
}
