.about{
    /* padding-top: 15vh; */
    padding: 6rem 5rem 0 5rem ;
}

.links{
    float: right;
}

.head{
    font-size: 3rem;
}

.headt{
    font-size: 1.5rem;
    padding-bottom: 1rem;
}

.text{
    padding-top: 2rem;
}

.pic{
    height: 200px;
    width: 200px;
    border-radius: 100px;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

@media screen and (max-width: 640px) {
    .about{
        padding: 4rem 2rem 0 2rem;
    }

    .head{
        font-size: 2rem;
    }

    .headt{
        font-size: 1rem;
    }

    .pic{
        height: 100px;
        width: 100px;
        border-radius: 50px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .links p{
        font-size: 0.8rem;
    }

    .longtext p{
        font-size: 1rem;
    }

    .des p{
        font-size: 1rem;
    }

    .text p{
        font-size: 1rem;
    }
}