.mask{
    padding-top: 30rem;
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000; /* Set a background color similar to the screenshot */
}

.hero{
    height: 100%;
    width: 100%;
    background-color: #000; /* Full background black */
    color: #fff; /* White text */
}

.detail{
    position: absolute;
    top: 20%;
    left: 5rem;
    text-align: left;
}

.firstpara p{
    font-family: "Caveat", sans-serif;
}

.latest{
    color: #fff;
    margin-top: 2vh;
    font-size: 1.3rem;
}

.highlights{
    color: #fff;
    margin-top: 1vh;
    font-size: 1.3rem;
}

li{
    list-style-type: none; /* Remove bullet points */
    text-decoration: none;
    font-size: 1.2rem;
}

p{
    color: #fff;
    font-size: 1.5rem;
}

.firstpara{
    margin-bottom: 1vh;
}

.firstpara p{
    font-size: 1.5rem;
}

.secondpara{
    font-size: 1.3rem;
}

a{
    text-decoration: none;
    color: #00ff00; /* Green links */
}

a:hover{
    text-decoration: underline;
}

@media screen and (max-width:640px){
    .hero{
        padding-top: 5vh;
    }
    .detail{
        top: 10%;
        left: 2rem;
    }

    .latest{
        font-size: 1rem;
    }

    .highlights{
        font-size: 1rem;
    }

    li{
        font-size: 1rem;
    }

    p{
        font-size: 1rem;
    }

    .firstpara p{
        font-size: 1rem;
    }

    .secondpara{
        font-size: 1rem;
    }
}